@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900');

html {
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Montserrat;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  font-family: Montserrat;
  outline: none;
}

.container {
  width: 100%;
  height: 100%;
}